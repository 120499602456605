import React from 'react'
import Layout from '../components/layout'

import { Link } from 'gatsby'

const ThanksPage = () => (
  <Layout>
    <p>Thanks for booking an initial appointment.</p>
    <p>You will shortly receive an email to confirm all details.</p>
    <p>If you have any question, please contact hello@duraj.space.</p>
    <p><Link to='/'>Return home</Link>.</p>
  </Layout>
)

export default ThanksPage
